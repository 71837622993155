export enum ParticipantStatus {
	ACTIVE = "ACTIVE",
	PAUSED = "PAUSED",
	COMPLETED = "COMPLETED",
	DISENROLLED = "DISENROLLED",
	PENDING_ACTIVATION = "PENDING_ACTIVATION",
	PENDING_TO_ENROLL = "PENDING_TO_ENROLL",
	PENDING_VERIFICATION = "PENDING_VERIFICATION",
	DENIED = "DENIED",
	INACTIVE = "INACTIVE",
}

export enum EnrollmentStatus {
	ACTIVE = "Active",
	PAUSED = "Paused",
	COMPLETED = "Completed",
	DISENROLLED = "Disenrolled",
	PENDING_ACTIVATION = "Pending Activation",
	PENDING_TO_ENROLL = "Pending to Enroll",
	PENDING_VERIFICATION = "Pending Verification",
	DENIED = "Denied",
	INACTIVE = "Inactive",
}

export enum EnrollmentReasons {
	RENEWED_MOTIVATION_OR_INTEREST = "RENEWED_MOTIVATION_OR_INTEREST",
	DISENROLLED_FROM_ANOTHER_PROGRAM = "DISENROLLED_FROM_ANOTHER_PROGRAM",
	INCOMPLETE_INITIAL_PARTICIPATION = "INCOMPLETE_INITIAL_PARTICIPATION",
	ADHERENCE_REINFORCEMENT = "ADHERENCE_REINFORCEMENT",
	REQUIREMENT_FOR_A_NEW_JOB = "REQUIREMENT_FOR_A_NEW_JOB",
	ENROLLED_FROM_THE_QUEUE_LIST = "ENROLLED_FROM_THE_QUEUE_LIST",
}

export type IParticipant = {
	_id: string;
	firstName: string;
	lastName: string;
	userId: string;
	dateJoined: Date;
	status: ParticipantStatus;
};

export interface ParticipantItem {
	_id: string;
	fullAddress: string;
	totalAmountEarnedByCurrentYear: number;
	incentivesEarned: number;
	lastEnrollment: LastEnrollment;
	participant: Participant;
	program: Program;
	organization: Organization;
	user: User;
	address: {
		addressLine1: string
		addressLine2: string
		city: string
		country: string
		locality: string
		participantId: string
		state: string
		postalCode: string
		zipCode: string
	}
}

export interface LastEnrollment {
	_id: string;
	participantId: string;
	programId: string;
	startDate: Date;
	endDate: Date;
	status: string;
	createdAt: Date;
	createdBy: string;
	updatedAt: Date;
	updatedBy: string;
	accountId: string;
	orgUnitId: string[];
	orgUnitLabelId: string[];
	hasCompletedFirstTimeValidation: boolean;
	hasCompletedTraining: boolean;
	cmStartDate: Date;
}

export interface Organization {
	_id: string;
	accountId: string;
	name: string;
	description: string;
	status: string;
	createdBy: string;
	updatedBy: string;
	isDeleted: boolean;
	createdAt: Date;
	updatedAt: Date;
	orgUnitLabelId: string[];
}

export interface Program {
	_id: string;
	name: string;
	status: string;
	requiredEligibility: boolean;
	requiredConsent: boolean;
	incentiveStructure: string;
	startDate: Date;
	endDate: Date;
	validationTimeOut: number;
	maxValidationsPeerWeek: number;
	maxParticipants: number;
	programDuration: number;
	maxEarnings: number;
	budgetLimit: number;
	incentiveLadderStart: number;
	incentiveLadderStep: number;
	incentiveLadderMax: number;
	incentiveLadderReset: number;
	validationRate: ValidationRate;
	createdBy: string;
	updatedBy: string;
	createdAt: Date;
	updatedAt: Date;
	requestWindows: any[];
	educationalOnboarding: Educational[];
	educational: Educational[];
	isDeleted: boolean;
	accountId: string;
	orgUnitId: string[];
}

export interface Educational {
	educationalId: string;
	order: number;
	_id: string;
}

export interface ValidationRate {
	rateType: string;
	days: number[];
	_id: string;
}

export interface Participant {
	_id: string;
	userId: string;
	status: string;
	dateOfBirth: string | undefined;
	dateJoined: Date;
	agreementStatus: boolean;
	tokenValidated: string;
	programId: string;
	notifications: any[];
	accountId: string;
	orgUnitId: string[];
	orgUnitLabelId: string[];
	virtualCardAccountId: string;
	updatedAt: Date;
	clientIdNumber: string;
	isArchived: boolean;
	address: {
		addressLine1: string;
		addressLine2: string;
		city: string;
		locality?: string;
		state: string;
		zipCode?: string;
		postalCode?: string;
	};
	reasonToMove: string;
}

export interface User {
	_id: string;
	email: string;
	phoneNumber: string;
	countryPhoneCode: string;
	firstName: string;
	lastName: string;
	authenticationId: string;
	type: string;
	status: string;
	fullName: string;
	dateOfBirth: Date;
}

export interface IParticipantItemSearched {
	_id: string;
	fullName: string;
}

export interface InvitationPayload {
	data: InvitationPayloadData;
	additionals: Additionals;
}

export interface Additionals {
	dateOfBirth: string;
	orgUnitId: string;
}

export interface InvitationPayloadData {
	generateParticipant: boolean;
	data: DataPayload;
}

export interface DataPayload {
	user: UserPayload;
	participant: ParticipantPayload;
	onboarding: Onboarding;
	enrollment: Enrollment;
	enrollmentBalance: EnrollmentBalance;
	medicationProof: MedicationProof;
}

export interface Enrollment {
	programId: null;
	startDate: Date;
	endDate: Date;
	status: string;
}

export interface EnrollmentBalance {
	amount: number;
	currency: string;
	transactions: Transaction[];
}

export interface Transaction {
	type: string;
	amount: number;
	date: Date;
	mechanism: string;
	operationId: string;
}

export interface MedicationProof {
	type: string;
	status: string;
	processDate: Date;
	remainingAttempts: number;
}

export interface Onboarding {
	status: string;
	startDateTime: Date;
	endDateTime: Date;
}

export interface ParticipantPayload {
	programId: null;
	status: string;
	facestatus: string;
	agreementStatus: boolean;
	dateJoined: Date;
}

export interface UserPayload {
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	authenticationId: string;
	type: string;
	status: string;
}

export interface ParticipantQueueItem {
	_id: string;
	userId: string;
	status: string;
	dateJoined: Date;
	agreementStatus: boolean;
	programId: null;
	notifications: any[];
	accountId: string;
	orgUnitId: string[];
	orgUnitLabelId: string[];
	updatedAt: Date;
	onboarding: QueueOnboarding;
	userIdAux: string;
	user: QueueUser;
}

export interface QueueOnboarding {
	_id: string;
	userId: string;
	status: string;
	type: string;
	onboardingId: string;
	__v: number;
}

export interface QueueUser {
	_id: string;
	email: string;
	phoneNumber: string;
	firstName: string;
	lastName: string;
	authenticationId: string;
	type: string;
	status: string;
	fullName: string;
}

export interface BeneficiaryEnrollmentPeriodsResponse {
	_id: string;
	startDate: Date;
	endDate: Date;
	program: Program;
}

export interface ParticipantCardBalanceResponse {
	cardBalance: CardBalance;
	message: string;
	amount: number;
}

export interface CardBalance {
	_id: string;
	participantId: string;
	accounts: Account[];
}

export interface Account {
	accountId: string;
	accountNumber: string;
	provider: string;
	type: string;
	status: string;
	cards: Card[];
	_id: string;
}

export interface Card {
	cardOrderId: string;
	cardId: string;
	issueDate: Date;
	expirationDate: Date;
	last4CardNumber: string;
	status: string;
	_id: string;
}

export interface DenyParticipantsPayload {
	participantIds: string[];
	userId: string;
}

export interface DenyParticipantsResponse {
	participantId: string[];
	message: string;
}
