import React, { useMemo, useState } from "react";
import {
	Box,
	Tabs,
	Tab,
	Button,
	Divider,
	CircularProgress,
} from "@mui/material";
import { UserFieldForm } from "./UserFieldForm";
import { RolesForm } from "./RolesForm";
import OUForm from "./OUForm";
import { yupResolver } from "@hookform/resolvers/yup";
import createUserSchema, { UserSchema } from "./useSchema";
import { FormProvider, useForm } from "react-hook-form";
import { IUserPayload } from "../../domain/user.interface";
import { useNavigate } from "react-router-dom";
import { IRole } from "../../domain/rol.interface";
import { useGetProgramsByAccount } from "../../hooks/usePrograms";
import { useFeedBack } from '../../providers/FeedBackProvider/FeedBackContext';

type Props = {
	isEditMode: boolean;
	user?: IUserPayload;
	onSubmit: (data: UserSchema) => void;
	saveDisabled: boolean;
	roles?: IRole[];
	submitLabel: string;
	currentRoleOnly?: IRole;
};

const UserForm = ({
	user,
	isEditMode,
	onSubmit,
	saveDisabled,
	roles,
	submitLabel,
	currentRoleOnly,
}: Props) => {
	const [tabValue, setTabValue] = useState(0);
	const { showSnackBar } = useFeedBack();
	const [isProgramRequired, setIsProgramRequired] = useState(false);
	const navigate = useNavigate();

	const methods = useForm({
		resolver: yupResolver(createUserSchema(isProgramRequired)),
		defaultValues: {
			firstName: user?.firstName,
			lastName: user?.lastName,
			email: user?.email,
			phoneNumber: user?.phoneNumber,
			npi: user?.npi,
			status: user?.status || "PENDING",
			roleId: user?.roles?.length ? user?.roles[0] : "",
			programs: user?.programs || [],
		},
	});

	console.log(methods.formState.errors);

	const handleTabChange = (
		event: React.SyntheticEvent<Element, Event>,
		value: any,
	) => {
		setTabValue(value);
	};

	const roleId = methods.watch("roleId", "");

	const isSysadminRole = useMemo(() => {
		const systemRoles = roles?.filter(rol => rol.type === "STANDARD_SYSTEM_ROLES");
		const isAdmin = (roleId && systemRoles && systemRoles?.length > 0 && systemRoles.some(rol => rol._id === roleId)) as boolean;
		setIsProgramRequired(isAdmin)
		return isAdmin;
	}, [roleId, roles]);

	const onClickSubmit = () => {
		// Trigger your custom event here
		if (
			!methods.getValues("firstName") ||
			!methods.getValues("lastName") ||
			!methods.getValues("email") ||
			!methods.getValues("status") ||
			methods.formState.errors.firstName ||
			methods.formState.errors.lastName ||
			methods.formState.errors.email ||
			methods.formState.errors.status
		) {
			setTabValue(0);
			return;
		} else if (!methods.getValues("roleId")) {
			showSnackBar("Please choose a use role first.", "warning");
			setTabValue(1);
			return;
		} else if ((!Array.isArray(methods.getValues("programs")) || methods.getValues("programs")?.length === 0) && !isSysadminRole) {
			showSnackBar("At least one organizational unit must be selected for this user.", "warning");
			setTabValue(2);
			return;
		}

		// Optionally, submit the form manually (even if invalid)
		methods.handleSubmit(onSubmit)(); // Notice the extra () to invoke handleSubmit
	};

	const accountId =
		process.env.REACT_APP_ACCOUNT_ID || "6682d71089b49a7e9418627c";
	const { data: programsList } = useGetProgramsByAccount(accountId);

	return (
		<FormProvider {...methods}>
			<Box
				sx={{
					flexGrow: 1,
					display: "flex",
					flexDirection: "column",
					p: 2,
					overflowY: "auto",
					backgroundColor: "white",
					borderRadius: 2,
				}}
			>
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					aria-label="user form tabs"
				>
					<Tab label="USER" />
					<Tab label="USER ROLE" />
					<Tab label="ORG UNITS" />
				</Tabs>
				<Box sx={{ mt: 2, flexGrow: 1, overflowY: "auto" }}>
					{tabValue === 0 && (
						<UserFieldForm
							control={methods.control}
							errors={methods.formState.errors}
							isEditMode={isEditMode}
							watch={methods.watch}
							setValue={methods.setValue}
						/>
					)}
					{tabValue === 1 && (
						<>
							{!roles ? (
								<Box
									display="flex"
									justifyContent="center"
									alignItems="center"
									height="240px"
								>
									<CircularProgress />
								</Box>
							) : (
								<RolesForm
									roles={roles}
									control={methods.control}
									currentRoleOnly={currentRoleOnly}
								/>
							)}
						</>
					)}
					{tabValue === 2 && (
						<OUForm
							roles={roles}
							programsList={programsList || []}
						/>
					)}
				</Box>
				<Divider sx={{ ml: -2, mr: -2 }} />
				<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
					<Button
						variant="outlined"
						sx={{ mr: 1 }}
						onClick={() => navigate("/users")}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={onClickSubmit}
						disabled={saveDisabled}
						startIcon={saveDisabled ? <CircularProgress size={24} /> : null}
					>
						{submitLabel}
					</Button>
				</Box>
			</Box>
		</FormProvider>
	);
};

export default UserForm;
