import React, { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppTheme } from "../../utils/theme";
import { useMenuStore } from "../../stores/menu.store";
import MenuItemButton from "../../components/MenuItemButton";
import MenuItemIcon from "../../components/MenuItemIcon";
import getIcon from "../../utils/getIcon";
import { useNavigate } from "react-router-dom";
import { useSession } from "../../hooks/useSession";
import { useMenu } from "../../hooks/useMenu";
import Avatar from "@mui/material/Avatar";
import useCurrentPath from "../../hooks/useCurrentPath";
import { getRandomInt } from "../../utils/getRandomInt";
import { useInviteParticipantModal } from "../../container/InviteParticipantModal/InviteParticipantModalContext";
import Skeleton from "@mui/material/Skeleton";
import { useFilters } from "../../providers/CampaignFiltersContext";
import SearchBar from "../../components/SearchBar/index";

const drawerWidth = 240;

const AvatarColors = ["#af4cab", "#abaf4c", "#4caf50", "#504caf"];

const CODE_INVITE_PARTICIPANT_ACTION = "WEB_PART_PAR_ADD_INVITE";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(2.5),
	marginTop: "80px",
	backgroundColor: theme.palette.surface.main,
	transition: theme.transitions.create("margin", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	height: "82px",
	display: "flex",
	flexDirection: "row",
	backgroundColor: "#fff",
	transition: theme.transitions.create(["margin", "width"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

type LayoutProps = {
	children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }: LayoutProps) => {
	const { user, logout } = useAuth0();
	const {
		isLoading: isSessionLoading,
		userHasAccess,
		userHasAccessToCode,
		data: currentUser,
	} = useSession();
	const navigate = useNavigate();
	const theme = useAppTheme();
	const [open, setOpen] = useState(false);
	const { active, setActive, toggleOpen, openList } = useMenuStore();
	const { data: menus, isLoading: isMenuLoading } = useMenu();
	const routePath = useCurrentPath();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const { setFilters, setPersistFilters } = useFilters();

	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const [avatarColor, setAvatarColor] = useState(AvatarColors[0]);

	const { openModal } = useInviteParticipantModal();
	const displayInviteParticipant = useMemo(() => {
		return userHasAccessToCode(CODE_INVITE_PARTICIPANT_ACTION);
	}, [userHasAccessToCode, currentUser]);

	const isLoading = useMemo(
		() => isSessionLoading && isMenuLoading,
		[isSessionLoading, isMenuLoading],
	);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const logoutWithRedirect = () => {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	};

	const handleDrawerOpen = () => setOpen(true);
	const handleDrawerClose = () => setOpen(false);

	const initials = useMemo(() => {
		const initialFirstName = currentUser?.firstName?.charAt(0).toUpperCase();
		const initialLastName = currentUser?.lastName?.charAt(0).toUpperCase();
		return `${initialFirstName}${initialLastName}`;
	}, [currentUser]);

	useEffect(() => {
		if (currentUser) {
			if (!userHasAccess(routePath) && routePath !== "/callback") {
				navigate("/accessDenied");
			}
		} else if (!isSessionLoading && !currentUser) {
			navigate("/accessDenied");
		}
	}, [currentUser, isSessionLoading]);

	useEffect(() => {
		if (!isSmallScreen) {
			setOpen(true);
		}
		setAvatarColor(AvatarColors[getRandomInt(0, 3)] as string);
	}, []);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					backgroundColor: theme.palette.surface.main,
					minHeight: "100vh",
					overflow: "hidden",
				}}
				data-testid="layout"
			>
				<CssBaseline />
				<AppBar
					position="fixed"
					open={open}
					className="flex"
					sx={{
						boxShadow: "none",
						borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
					}}>
					<Toolbar className="flex-1 gap-2.5">
						<IconButton
							size="large"
							edge="start"
							aria-label="menu"
							data-testid="drawer-button"
							onClick={() => (open ? handleDrawerClose() : handleDrawerOpen())}
						>
							<MenuIcon />
						</IconButton>
						<SearchBar />
						{!isSmallScreen && displayInviteParticipant && (
							<Button
								size="large"
								onClick={() => {
									openModal();
								}}
								color="primary"
								variant="contained"
								sx={{
									borderRadius: "8px",
									textTransform: "none",
									height: "3rem",
									textAlign: "center",
								}}
							>
								INVITE PARTICIPANT
							</Button>
						)}

						<Box
							sx={{
								marginLeft: "auto",
								alignItems: "center",
								display: "flex",
							}}
						>
							{/* <Tooltip title="Notifications">
								<IconButton size="large" onClick={handleOpenNotificationMenu}>
									<Badge badgeContent={2} color="secondary">
										<NotificationsNoneOutlinedIcon className="text-icon-primary" />
									</Badge>

								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: "45px" }}
								id="menu-notifications"
								anchorEl={anchorElNotification}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorElNotification)}
								onClose={handleCloseNotificationMenu}
								data-testid="notification-menu"
							>
								<MenuItem>
									<Typography textAlign="center">Notification 1</Typography>
								</MenuItem>
								<MenuItem>
									<Typography textAlign="center">Notification2</Typography>
								</MenuItem>
							</Menu> */}
							<Tooltip title="Open settings">
								<Button
									size="large"
									onClick={handleOpenUserMenu}
									sx={{
										textTransform: "none",
										justifyContent: "space-between",
									}}
									data-testid="user-button"
								>
									{isSmallScreen ? (
										<Box sx={{ display: "flex", alignItems: "center" }}>
											<Avatar sx={{ bgcolor: avatarColor }}>{initials}</Avatar>
											<KeyboardArrowDownIcon className="text-dark" />
										</Box>
									) : (
										<div className="flex items-center gap-4 w-full">
											<Avatar
												sx={{ bgcolor: avatarColor }}
												src={user?.avatarUrl}
												alt={currentUser?.firstName}
											>
												{!user?.avatarUrl && initials}
											</Avatar>
											<div className="flex flex-col flex-1 justify-start items-start">
												<span className="text-base text-primary font-bold">
													{currentUser?.firstName} {currentUser?.lastName}
												</span>
												<span className="text-xs text-secondary">
													{currentUser?.roles?.length &&
														currentUser?.roles[0].name}
												</span>
											</div>
											<KeyboardArrowDownIcon className="text-dark" />
										</div>
									)}
								</Button>
							</Tooltip>
							<Menu
								sx={{ mt: "45px" }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
								data-testid="user-menu"
							>
								<MenuItem
									onClick={() => {
										navigate("/helpsupport");
									}}
									data-testid="menu-item"
								>
									<Typography textAlign="center">Support</Typography>
								</MenuItem>
								<MenuItem
									onClick={logoutWithRedirect}
									data-testid="menu-item-logout"
								>
									<Typography textAlign="center">Sign out</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Toolbar>
				</AppBar>
				<Drawer
					sx={{
						width: drawerWidth,
						flexShrink: 0,
						"& .MuiDrawer-paper": {
							width: drawerWidth,
							boxSizing: "border-box",
							flexDirection: "column",
							justifyContent: "space-between",
							backgroundColor: theme.palette.menu.main,
							height: "100vh",
							// height: "100%",
							padding: "1.25rem 0.5rem",
							gap: "1rem",
						},
					}}
					variant="persistent"
					anchor="left"
					open={open}
					data-testid="drawer"
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							padding: "16px",
						}}
					>
						<img
							src="/assets/LogoBackground_512x512.png"
							alt="BACK OFFICE NALCAM"
							style={{
								width: "50px", // Adjust the width as needed
								height: "50px", // Adjust the height as needed
								objectFit: "contain",
							}}
						/>
						<Typography
							variant="h6"
							component="div"
							sx={{
								marginLeft: "16px",
								color: theme.palette.surface.light,
								fontWeight: "bold",
							}}
						>
							NALCAM
						</Typography>
					</Box>
					{/* TODO: Read the route param to set active or open item on menu */}
					{isLoading ? (
						<div className="flex flex-col gap-8 justify-start h-full items-center mt-4">
							<div className="flex gap-2.5">
								<Skeleton variant="circular" width={24} height={24} />
								<Skeleton variant="text" width={160} height={24} />
							</div>
							<div className="flex gap-2.5">
								<Skeleton variant="circular" width={24} height={24} />
								<Skeleton variant="text" width={160} height={24} />
							</div>
							<div className="flex gap-2.5">
								<Skeleton variant="circular" width={24} height={24} />
								<Skeleton variant="text" width={160} height={24} />
							</div>
							<div className="flex gap-2.5">
								<Skeleton variant="circular" width={24} height={24} />
								<Skeleton variant="text" width={160} height={24} />
							</div>
							<div className="flex gap-2.5">
								<Skeleton variant="circular" width={24} height={24} />
								<Skeleton variant="text" width={160} height={24} />
							</div>
							<div className="flex gap-2.5">
								<Skeleton variant="circular" width={24} height={24} />
								<Skeleton variant="text" width={160} height={24} />
							</div>
						</div>
					) : (
						<List className="px-2.5" sx={{ flexGrow: 1 }}>
							{menus?.map((item) => (
								<div key={item.id}>
									<ListItem disablePadding className="mb-2">
										<MenuItemButton
											active={item.id === active}
											onClick={() => {
												if (item.type === "link") {
													if (item.route.startsWith("http")) {
														window.open(item.route, "_blank");
													} else {
														// useMenuStore.getState().setActive(item.id);
														setActive(item.id);
														if (item.title === "Participants") {
															setFilters({
																campaignId: "",
																orgUnitsIds: [""],
																orgUnitLabelId: "",
																status: ["ACTIVE"],
																search: "",
																pageNumber: 0,
																pageSize: 10,
																sortField: "user.fullName",
																sortOrder: 1,
																isArchived: false,
															});

															setPersistFilters(false);
														}
														navigate(item.route);
													}
												} else {
													// useMenuStore.getState().toggleOpen(item.id);
													console.log("click", item.id);
													toggleOpen(item.id);
												}
											}}
										>
											<MenuItemIcon>{getIcon(item.icon)}</MenuItemIcon>
											<ListItemText primary={item.title} />
											{item.type === "parent" &&
												(openList[item.id] ? <ExpandLess /> : <ExpandMore />)}
										</MenuItemButton>
									</ListItem>
									{item.children && (
										<Collapse
											in={openList[item.id]}
											timeout="auto"
											unmountOnExit
										>
											<List component="div" disablePadding>
												{item.children.map((child) => (
													<ListItem key={child.id} disablePadding>
														<MenuItemButton
															active={child.id === active}
															onClick={() => {
																// useMenuStore.getState().setActive(child.id);
																setActive(child.id);
																navigate(child.route);
															}}
														>
															<MenuItemIcon>{getIcon(child.icon)}</MenuItemIcon>
															<ListItemText primary={child.title} />
														</MenuItemButton>
													</ListItem>
												))}
											</List>
										</Collapse>
									)}
								</div>
							))}
						</List>
					)}
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							textAlign: "center",
						}}
					>
						<Typography
							variant="labelMedium"
							component="div"
							sx={{
								color: theme.palette.surface.light,
								lineHeight: 1.5,
							}}
						>
							NALCAM - CMI © {new Date().getFullYear()}
							<br />
							v1.0.1
						</Typography>
					</Box>
				</Drawer>
				<Main open={open}>{children}</Main>
			</Box>
		</>
	);
};

export default Layout;
